.faq-section{
    position: relative;
    width: 100vw;
    padding-top: 6rem;
    padding-bottom: 6rem;
    text-align: center;
    z-index: 2;
    background-color: black;
}

.faq-section-inner{
    margin: auto;
    margin-top: 2rem;
    width: 700px;
    max-width: 90vw;
}
.faq{
    height: 105px;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    text-align: start;
    border-radius: 13px;
    // background-color: #;
    box-shadow: 0px 0px 15px #2aff00;
    margin-top: 3.5rem;
    transition: height 250ms ease-in-out;
    p{
        margin:0px 20px 28px 20px;
        line-height: 25px;
    }
    padding-bottom:2rem;
}
.faq-question{
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 105px;
    width: 100%;
    span{
        max-width: 90%;
    }
}
.faq-answer{
    width: fit-content;
    height: fit-content;
    margin: auto;
}
