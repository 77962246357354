@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$main-blue:#3fc4ff;

.App {
  position: relative;
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-tap-highlight-color: transparent;  
 
}

@keyframes animation{
  0%{
    width: 0;
  }
  100%{
    width: 100vw;
  }
}

.App-inner{
  width: 100vw;
  // overflow: hidden;
  position: relative;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
 
  // background-image: url(./assets/images/background2.jpg);

}
// ::-webkit-scrollbar{
//   display: none;
// }

.bg-image-holder{
  
  display:flex;
  align-items:flex-end;
  justify-content:center;
  height: calc(100vh - 90px) ;
  width: 100vw;
  overflow: hidden;
  background-color: black;
  // background-image: url(./assets/images/header.webp);
  // background-position:center;
  // background-position-y:top;
  // background-size:cover;
  position: relative;
  top:0;
  z-index: 3;
  img{
    width:calc((100vh - 100px) / 0.422);
    
  }
}
.bg-image-holder::after{
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 154px;
  z-index: 1;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000000 96.3%);
}
// @media screen and (max-width:700px){
//   .bg-image-holder{
   
//     // height: calc(100vw * 0.3875);
//     height: 70vw;
    
//   }
// }


.main-logo{

  position: absolute;
}

.bg-image{
  width:100vw;
  min-height: 100vh;
  object-fit: contain;
  
}

.d-grid{
  display: grid;
}
.font-faq{
  font-size: 1.5rem;
}
.font-xl{
  // background-color: red;
  // width: fit-content;
  text-align: center;
  font-size:65px;
}
.font-l{
  font-size: 2.5rem;
}
.font-m{
  font-size: 2.2rem;
}
.font-s{
  font-size: 1.25rem;
}
.font-xs{
  font-size: 1rem;
  line-height: 22px;
}

.family-orbitron{
  font-family: 'Montserrat',sans-serif;
}

.family-mono{
  font-family: 'Roboto',sans-serif;
}


.outer-section{
  .inner{
    text-align: justify;
    max-width: 700px;
  }
}

.display-flex{
  display: flex;
}



.text-green{
  color:#2aff00;
}
.text-white{
  color:white;
}
.text-gray{
  color:gray;
}
.text-light-gray{
  color:#9ba0a8;
}
.text-light-blue{
  color: #9FC9D3;
}
.text-blue{
  color:#008c91;
}
.text-red{
  color:#c43137;
}
.text-pink{
  color:#f16c8b
}
.text-lighter-gray{
  color:#b4b8be;
}
.extra-bold{
  font-weight:800;
}

.weight-bold{
  font-weight:bold;
}
.weight-semi-bold{
  font-weight:600;
}
.weight-extra-bold{
  font-weight:800;
}
.weight-black{
  font-weight:900;
}
.weight-normal{
  font-weight: 400;
}
.weight-light{
  font-weight: 300;
}
.align-end{
  justify-content: flex-end;
}


.mt-point5{
  margin-top:0.5rem;
}

.mt-1{
  margin-top:1rem;
}
.mt-2{
  margin-top:2rem;
}
.mb-1{
  margin-bottom:1rem;
}
.mb-2{
  margin-bottom:2rem;
}
.mb-4{
  margin-bottom:3rem;
}
.mt-8{
  margin-top:8rem;
}

@media screen and (max-width:1100px){
  .font-xl{
    font-size:5.5vw;
  }
}

@media screen and (max-width:800px){
  .font-xl{
    font-size:3.5rem;
  }

  .font-l{
    font-size: 2rem;
  }
  .font-m{
    font-size: 1.9rem;
  }
  .font-s{
    font-size: 1.3rem;
    
  }

  .mt-2{
    margin-top:2rem;
  }
  .mb-1{
    margin-bottom:1rem
  }
  .mb-4{
    margin-bottom:1rem;
  }
  .mb-2{
    margin-bottom:2rem
  }
  .mt-8{
    margin-top:2rem;
  }
  


}

@media screen and (max-width:500px){
  .font-xl{
    font-size:3rem;
  }
  .font-l{
    font-size: 1.5rem;
  }
  
}


.text-center{
  text-align: center;
}
.text-start{
  text-align: start;
}

.center-children{
  display: flex;
  justify-content: center;
  align-items: center;
}


.social-logo{
  height: 16px;
  width: 16px;
 
  cursor: pointer; 

  img{
    height: 100%;
    width: 100%;
  }
}

.contract-address{
  cursor: pointer;
  text-decoration: none;
  &:hover{
    text-decoration: underline;
   
  }
}
.section{
  max-width: 1110px;
}
.outer-section{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background:#131313;

}

@media  screen and (max-width:500px) and (min-height:500px) {
  .bg-image{
   width: 200vh;
  }

}

.line-height-33{
  line-height: 36px;
}
.line-height-25{
  line-height: 25px;
}