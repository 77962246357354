$main-blue:#2aff00;

.Header{
    position: sticky;
    top:0;
    left:0;
    right:0;
    z-index: 100;
    height: 80px;
    width: 100vw;
    background-color: rgba(0,0,0,0.99);
    backdrop-filter: blur(15px);
    transition: all 200ms ease-in-out;
}
.Header-inner{
    // background-color: blueviolet;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1450px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 35px 0 45px;
    box-sizing: border-box;
    margin: auto;
}
.Header-logo{
    position: absolute;
    left:0;
    right:0;
    margin:auto;
    // top:20px;
    height:90%;
    // margin-right: 50px;
}
.Header-left{
    height: 100%;
    flex:1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span{
        margin-right: 28px;
        cursor: pointer;
        &:hover{
            color:$main-blue
        }
    }
    
}
.Header-right{
    display: flex;
    align-items: center;
    height: 100%;
    width: 100px;
    justify-content: space-between;
}
.header-menu-btn{
    z-index: 30;
    display: none;
    height: 42px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 1rem;
}
.header-menu-btn>img{
    height: 100%;
}



.Header-mobile-nav{
    display: none;
}
@media screen and (max-width:1300px){
    // .Header-logo{
    //     height:65%;
    //     margin-right: 50px;
    //     position: absolute;
    //     left: 0;
    // }
    .Header{
        height:75px;
        padding: 0px;
        box-sizing: border-box;
        position: sticky;
    }
    // .Header-right{
    //     display: none;
       
    // }
    .Header-right{
        position: absolute;
        height: 100%;
        width: 90px;
        margin-left: auto;
        margin-right: 85px;
        left: 0;
        right:0;
        justify-content: space-between;
        // background-color: red;
    }
    .Header-left-nav{
        display: none;
    }
    .Header-mobile-nav{
        display: none;
        overflow:hidden;
        transition: all 500ms ease-in-out;
        
        z-index: 10;
        height: 0vh;
        width: 100vw;
        position:fixed;
        top:0;
        left:0;
        background-color: black;
        .Header-mobile-nav-inner{
            background-size: cover;
            display: flex;
            height: 100vh;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100vw;
            img{
                margin-bottom: 10vh;
                height: 16vw;
            }
            span{
                margin-top:30px;
                cursor: pointer;
                &:hover{
                    color:$main-blue
                }
            }
        }
        
    }
  
    .header-menu-btn{
        display: flex;
    }
}
.social-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: #f16c8b;
    cursor: pointer;
    border:none;

    img{
        height: 74%;
    }
    &:hover{
        height: 45px;
        width: 45px;
    }
}

.header-social-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    @media screen and(max-width:500px) {
        display: none;

    }
}
.header-discord-btn,.header-twitter-btn,.header-insta-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:0.7rem;
    height: 39px;
    width: 39px;
    border-radius: 50%;
    border:none;
    cursor: pointer;
    img{
        height: 53%;
    }
}
.header-twitter-btn{
    background-color: #1DA1F2;

}
.header-discord-btn{
    background-color: #7289DA;
}
.header-insta-btn{
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    img{
        height: 72%;
    }
}