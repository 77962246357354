.Carousel{
    width: 100%;
    // background-color: white;
    padding:56px 0px;
    // background-image: url(../../assets/images/bg.png);
    background-size: cover;
    position: relative;
}
.carousel-bgHolder{
    overflow: hidden;
    opacity: 0.2;
    top:0;
    position: absolute;
    height: 100%;
    img{
        width: 100vw;
    }
}


.Carousel-Header{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Carousel-Header-title{
    position: relative;
    font-size: 30px;
    text-align:center;
    white-space: pre-line;
    display: inline-block;
    
}
.Carousel-Divider{
    position: relative;

    width:50px;
    margin:30px 0px 42px;
    border-bottom: 0.125em solid #2aff00;
}
.Carousel-Footer-Image{
    height: 70px;
    width: 70px;
    margin-left:10px;
    cursor: pointer;
    border:1px solid rgb(31, 31, 31)
}
.Carousel-BodyImage{
    // height:540px;
    width:600px;
    margin-left: 5px;
    transition: opacity 420ms ease-in-out 0s,
}
.Carousel-Footer{
    width: 1125px;
    margin-left: auto;
    margin-right: auto;
   
}
.Carousel-Body{
    width: 1210px;
    height: 600px;
    display: flex;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    position: relative;
    touch-action: pan-y pinch-zoom;
    position: relative;
    align-items: center;
}
.Carousel-InnerBodyChild{
    display: flex;
}

.Carousel-InnerBody{
    position:absolute;
    display: flex;
    touch-action: pan-y pinch-zoom;
    // transition :left 500ms ease-in-out;
}
.Carousel-ButtonPrevious, .Carousel-ButtonNext{
    background-color:rgba(22, 22, 22, 0.5);
    padding:8px 4px 8px 2px;
    position: absolute;
    color:white;
    display: flex;
    align-items: center;
    border:none;
    cursor:pointer;
}
.Carousel-ButtonNext{
    padding:8px 2px 8px 4px;
    right:0;
}


@media screen and (max-width: 1150px) and (min-width: 775px){
    .Carousel-BodyImage{
        // height:400px;
        width:400px;
    }
    .Carousel-Body{
        width: 805px;
        height: 400px;
    }
    .Carousel-Footer{
      
        width:741px;
    }
}
@media screen and (max-width: 774px)  {
    .Carousel-BodyImage{
        // height:200px;
        width:200px;
    }
    .Carousel-Body{
        width: 405px;
        height: 200px;
    }
    .Carousel-Footer{
        display:none;
    }
    .Carousel-Header-title{
        font:32px 'Montserrat',sans-serif;   
        font-weight: 700;
    }
    .Carousel{
        margin-bottom: -35px;
    }
}
@media screen and (max-width: 449px) {
    .Carousel-BodyImage{
        // height:175px;
        width:175px;
    }
    .Carousel-Body{
        width: 355px;
        height: 175px;
    }
   
}
@media screen and (max-width: 355px) {
    .Carousel-BodyImage{
        // height:165px;
        width:165px;
    }
    .Carousel-Body{
        width: 330px;
        height: 165px;
    }
    
}

.left-arrow,.right-arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 80px;
    width:45px;
    background-color: rgba(0,0,0,0.6);
    cursor: pointer;
    z-index: 10;
    img{
        height: 50%;
    }
}
// .left-arrow{
//     left:5px;
// }
.right-arrow{
    right:0;
    img{
        transform: rotate(180deg);
    }
}