$white:#ffffff;
$-green:#2aff00;

.MintSection{
    position: relative;
    z-index: 10;
    background-color: black;
    width: 100vw;
    padding: 5rem 0 5rem 0;
    overflow: hidden;
}
.MintSection-inner{
    position: relative;
    top:300px;
    opacity: 0;
    display: flex;
    position: relative;
    z-index: 10;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 375px;;
    box-sizing: border-box;
    transition: all 500ms ease-in-out;
    margin: auto;
    text-align: center;
    max-width: 90vw;
}

.MintSection-left{
    display: grid;
   
    flex-direction: column;
  
}

.Mint-section-right{
    width: 100%;
}
.Mint-section-right>img{
   
    width: 100%;
    border-radius: 25px;
    box-shadow: 0px 0px 15px #2aff00;

}




.MintSection-left-bottom{
    display: flex;
    margin-top: 20px;
}

.MintSection-bossNum-top,.MintSection-bossNum-bottom{
    margin:0 0 20px 0;
    display: flex;
  
    height: 55px;
    width:100%;
    justify-content: space-between;
}
.MintSection-bossNum-bottom{
    margin:0;
    width: 100%;
}
.MintSection-bossNum-top>input{
    height: 51px;
    // flex:1;
    border:2px solid $-green;
    outline:none;
    background: transparent;
    max-width: 55%;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0px 0px 15px  $-green;

}
.mint-plus-btn,.mint-button,.mint-amount-btn{
    height: 100%;
    // aspect-ratio: 1;
    border:2px solid $-green;
    border-radius: 12px;
    cursor: pointer;
    background: transparent;
    box-shadow: 0px 0px 5px  $-green;
    color:black !important;
}
.mint-plus-btn{
    width:55px;
}

.mint-plus-btn:hover{
    background:  $-green;
    color:black;
}

.mint-button{
   
    height: 55px;
    width:100%;
    background:  $-green;
}
.mint-button:hover{
    background: transparent;
    color:$white;
}
