$shadow: 5px 5px 10px rgba(0,0,0,0.3);

.footer{
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height:270px;
    width:100vw;
    background:#000000;    
    background-color: black;
    box-shadow: 0px 0px 15px #2aff00;

}
.footer-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    width: 100%;
    height: 100%;
}
.footer-inner-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70%;
    width: 40%;
}
.footer-logo{
    height: 45%;
    margin-bottom: 1rem;
}
.footer-social-div{
    display: flex;
    justify-content: space-between;
    height: 70%;
   
}
.footer-discord-btn,.footer-twitter-btn,.footer-insta-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0.7rem;
    height: 52px;
    width: 52px;
    border-radius: 50%;
    border:none;
    cursor: pointer;
    box-shadow: $shadow;
    img{
        height: 53%;
    }
}
.footer-twitter-btn{
    background-color: #1DA1F2;

}
.footer-discord-btn{
    background-color: #7289DA;
}
.footer-insta-btn{
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    img{
        height: 72%;
    }
}
@media screen and (max-width:1000px) {
    .footer-inner{
        flex-direction: column;
    }
    .footer-inner-left{
        
        width: unset;
        text-align: center;
    }.footer-social-div{
      
        margin-right: unset;
    }
   
}