.team{
    position: relative;
    z-index: 10;
    width: 100vw;
    padding-top:10rem;
    text-align: center;
    background-color: black;
}
.team-inner{
    width: 1110px;
    margin: auto;
    // background-color: red;
    max-width: 85vw;
}
.team-inner-bottom{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    margin:3.5rem auto 0 auto ;
    // background-color: green;
}
.team-wrapper{
    opacity: 0;
    position: relative;
    top:300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: red;
    width:26%;
    
    // height:450px;
    text-align: center;
    margin-bottom: 3.5rem;
    img{
       width: 100%;
       border-radius: 10px;
       box-shadow: 0px 0px 15px #2aff00;
        &:hover{
            box-shadow: 0px 0px 45px #2aff00;

        }
    }
    p{
        margin-top: 1rem;
    }
    
}
// .team-title{
//     max-width: 60%;
// }
#team1{
    transition: top 700ms ease, opacity 700ms ease;
}
#team2{
    transition: top 1s ease, opacity 900ms ease;

}
#team3{
    transition: top 1.3s ease, opacity 1.1s ease;

}
#team4{
    transition: top 1.6s ease, opacity 1.2s ease;

}

@media screen and (max-width:1000px){
    .team-inner{
        margin-top: 6rem;
    }
    // .team-inner-bottom{
    //     width: 100%;
       
    // }
    .team-wrapper{
       
        width:40%;
        margin-bottom: 3rem;
    }
    .team-title{
        max-width: 100%;
    }
}
@media screen and (max-width:650px){
    .team-inner{
        margin-top: 4rem;
    }
    .team-inner-bottom{
        justify-content: center;
    }
    .team-wrapper{
       
        width:80%;
        margin-bottom: 3rem;
    }
    
}