$shadow: 2px 2px 6px #333333;


.About{
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    background-color: black;
}
.About-inner{
    position: relative;
    left:100vw;
    opacity: 0;
    transition: all 500ms ease-in-out;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 800px;
    max-width: 90vw;
}
@media screen and (max-width:550px) {
    .About-inner{
        padding: 2rem 0 2rem 0;        
    }
}
// .About-left{
//     max-width: 55%;
//     text-align: justify;
//     position: relative;
//     top:300px;
//     opacity: 0;
//     transition: top 750ms ease, opacity 750ms ease;
//     background-color: red;
// }
// .about-img{
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;
//     height:400px;
//     width: 400px;
//     border-radius: 20px;
//     img{
//         height: 45%;
//         box-shadow: $shadow;
//         border-radius: 14px;
//     }
//     opacity: 0;
//     position: relative;
//     right:-600px;
//     transition: right 750ms ease, opacity 750ms ease;

// }

// @media screen and (max-width:1150px){
//     // .About-inner{
//     //     max-width: 90vw;
//     //     align-items: center;
//     // }
//     // .about-img{
//     //     height: 30vw;
//     //     width: 30vw; 
//     // }
// }

// @media screen and (max-width:900px){
  
//     // .About-left{
//     //     max-width: 700px;
//     //     margin-bottom:2rem;
//     //     text-align: center;
//     // }
//     // .about-img{
//     //     width: 700px; 
//     //     height:  700px;
//     //     margin-bottom:2rem;
//     // }
// }
// @media screen and (max-width:800px){
   
//     // .About-left{
//     //     max-width: 85vw;
//     //     text-align: start
//     // }
//     // .about-img{
//     //     width: 85vw; 
//     //     height:  85vw;
//     // }
// }