.mint-roadmap{
    position: relative;
    width: 100%;
    text-align: center;
    
    // padding-bottom: 3rem;
    // background-color: rgba(255, 1, 10, 0.6);
    opacity: 0;
    p{
        line-height: 35px;

    }
    z-index: 2;
    animation:animation2 2s forwards ;
}

@keyframes animation2{
    0%{
      opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
  
.mint-roadmap-inner{
    position: relative;
    margin: auto;
    width: 910px;
    flex-wrap: wrap;
  
    max-width: 80vw;
    z-index: 2;
    
    margin-top: 2.7rem;
}
.blockers{
    position:absolute;
    top:0;
    background-color: burlywood;
    height: 200px;
    width: 100%;
    z-index: 3;
}
.mint-roadmap-title{
    padding-top: 7rem;
    position: relative;
    
    padding-bottom: 7rem;
    z-index: 3;
    background-color: black;
}
.mint-roadmap-section{
    top:90px;
    opacity: 0;
    transition: all 250ms ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: start;
    border-radius: 15px;
    padding: 27px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 2rem;
    span{
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
}
@media screen and (max-width:470px){
    .mint-roadmap-inner{
        max-width: 70vw;
    }
    
    .mint-roadmap-section{
        width: 87vw;
    }
}
.mint-roadmap-circle{
    display: flex;
    align-items: center;
    justify-content: center;
    left:-47px;
    position:absolute;
    height: 60px;
    width: 60px;
    background-color: white;
    border-radius: 50%;
    color:black;
    transition: all 50ms ease-in-out;
}
.mint-roadmap-section:hover>.mint-roadmap-circle{
    background-color: white;
    color:black;
    text-shadow: none;
}
.project-progress{
    position: absolute;
    height: 100%;
    width: 6px;
    background-color: #2aff00;
    flex:1;
    left:-19px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.3);
    overflow: hidden;
    z-index: -5;
}
.project-progress-inner{
    position: fixed;
    background-color: white;
    top:0;
    bottom:0;
    height: 500px;
    margin: auto;
    width: 6px;
    z-index: -1;
}
